import { animateNavOnScroll } from "./animations.js";

document.addEventListener("DOMContentLoaded", () => {
    /*--------- NAVIGATION -------- */

    animateNavOnScroll();

    //** HIDE ON SCROLL */
    const navbar = $(".nav");
    const banner = $(".banner");
    let initialTop = 0;


    if ($(window).width() > 769) {
        $(document).on("scroll", function() {
            const fromTop = $(window).scrollTop();

            if (fromTop > $(window).height()) {
                if (fromTop > initialTop) {
                    navbar.addClass("sticky");
                } else {
                    navbar.removeClass("sticky");
                }
            }
            initialTop = fromTop;
        });
    }
});