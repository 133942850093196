
const animateNavOnScroll = () => {
    if (pageYOffset < 50) {
        $(".navbar").css("background-color", "transparent");
    } else {
        $(".navbar").css("background-color", "rgb(252, 244, 238)");
    }
    
    window.addEventListener("scroll", function() {
        if (pageYOffset > 200) {
            $(".navbar").css("background-color", "rgb(252, 244, 238, 1)").css("transition", "0.5s ease-in-out ");
            $(".navbar > li > a").css("color", "rgb(77, 79, 81)");
            $(".navbar__logo").css("color", "rgb(77, 79, 81)");
        } else if (pageYOffset > 100) {
            $(".navbar").css("background-color", "rgba(252, 244, 238, 0.75)").css("transition", "0.5s ease-in-out ");
            $(".navbar__item > a").css("color", "rgba(77, 79, 81 0.75)");
            $(".navbar__item > a").css("color", "rgba(77, 79, 81, 1)");
            $(".navbar__logo").css("color", "rgba(77, 79, 81 0.75)");
            $(".navbar__logo").css("color", "rgba(77, 79, 81, 1)");
        } else if (pageYOffset > 30) {
            $(".navbar").css("background-color", "rgba(252, 244, 238, 0.5)").css("transition", "0.5s ease-in-out ");
            $(".navbar__item > a").css("color", "rgba(77, 79, 81 0.5)");
            $(".navbar__item > a").css("color", "rgba(77, 79, 81 1)");
            $(".navbar__logo").css("color", "rgba(77, 79, 81 0.5)");
            $(".navbar__logo").css("color", "rgba(77, 79, 81 1)");
        } else {
            $(".navbar").css("background-color", "transparent").css("transition", "0.5s ease-in-out ");
            $(".navbar__item > a").css("color", "rgba(255, 255, 255, 1)");
            $(".navbar__logo").css("color", "rgba(255, 255, 255, 1)");
        }
    });
};

export { animateNavOnScroll };