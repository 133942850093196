import jQuery from 'jquery';
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';


// Globals
// window.$ = jQuery;

// Sass
import '../sass/index.scss';

// Images
import slider_one from '../images/unsplash/boat-in-ocean.jpg';
import slider_two from '../images/slider/glass-pool.jpg';
import slider_three from '../images/slider/3.jpg';
import slider_four from '../images/slider/4.jpg';
import unleash_your_paradise from '../images/sections/unleash_your_paradise.jpg';
import explore_icon from '../images/icons/explore.png';
import experience_icon from '../images/icons/experience.png';
import indulge_icon from '../images/icons/indulge.png';
import discover from '../images/sections/discover.jpg';
import design from '../images/sections/design.jpg';
import book from '../images/sections/book.jpg';
import packBags from '../images/sections/pack-bags.jpg';

// Common Components
import './components/banner';
import './components/nav';
import './components/footer';
import './modules/mobile-navbar';

// Modules
import { stichImageToBGElement, stichImageToDomElement } from './modules/image-helper';
import './modules/common-images';
import './modules/nav';

// Hide banner on click
const addCloseBannerEventListerner = () => {
    let btn = document.getElementById('jsCloseButton');
    let banner = document.getElementById('banner');

    if (!localStorage.getItem('cookie-banner')) {
        banner.style.display = 'grid';
    }

    btn.addEventListener('click', function() {
        localStorage.setItem('cookie-banner', 'fasle');
        banner.style.display = 'none';
    });

    return;
};

const images = [{
        id: 'slider_1',
        img: slider_one,
        bgImage: true,
    },
    {
        id: 'slider_2',
        img: slider_two,
        bgImage: true,
    },
    {
        id: 'slider_3',
        img: slider_three,
        bgImage: true,
    },
    {
        id: 'slider_4',
        img: slider_four,
        bgImage: true,
    },
    {
        id: 'unleash_your_paradise_img',
        img: unleash_your_paradise,
        bgImage: false,
    },
    {
        id: 'experience_icon',
        img: experience_icon,
        bgImage: false,
    },
    {
        id: 'indulge_icon',
        img: indulge_icon,
        bgImage: false,
    },
    {
        id: 'explore_icon',
        img: explore_icon,
        bgImage: false,
    },
    {
        id: 'design',
        img: design,
        bgImage: false,
    },
    {
        id: 'discover',
        img: discover,
        bgImage: false,
    },
    {
        id: 'book',
        img: book,
        bgImage: false,
    },
    {
        id: 'pack-bags',
        img: packBags,
        bgImage: false,
    },
    {
        id: 'img-1',
        img: discover,
        bgImage: false,
    },
    {
        id: 'img-2',
        img: book,
        bgImage: false,
    },
    {
        id: 'img-3',
        img: packBags,
        bgImage: false,
    },
    {
        id: 'img-4',
        img: slider_four,
        bgImage: true,
    },
    {
        id: 'img-5',
        img: unleash_your_paradise,
        bgImage: false,
    },
    {
        id: 'img-6',
        img: slider_one,
        bgImage: false,
    },
    {
        id: 'img-7',
        img: slider_three,
        bgImage: true,
    },
    {
        id: 'img-8',
        img: slider_two,
        bgImage: false,
    },
];


images.forEach(obj => {
    if (obj.bgImage == true) {
        stichImageToBGElement(obj.id, obj.img);
    }

    stichImageToDomElement(obj.id, obj.img);
});

var swiper = new Swiper(".mySwiper", {
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});


new Swiper('.testimonials__slider__swiper', {
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});

// Tab Navigation on Home page
var tabs = document.getElementsByClassName('content__tabs__tab');

Array.prototype.forEach.call(tabs, function(tab) {
    tab.addEventListener('click', setActiveClass);
});

function setActiveClass(evt) {
    Array.prototype.forEach.call(tabs, function(tab) {
        tab.classList.remove('active');
    });

    evt.currentTarget.classList.add('active');
}


// Execute event listeners
addCloseBannerEventListerner();